import gtag, { install } from 'ga-gtag';
import "cookieconsent";


const enableCookies = () => {
    install('G-T6DCP2QH78');
};

// Cookie Consent - NOTE: this is version 3 syntax - V4 is not out yet.
// Options at https://www.osano.com/cookieconsent/documentation/javascript-api/
window.cookieconsent.initialise({
    cookie: {
        domain: 'sarahgatford.co.uk',
        secure: true,
    },
    container: document.querySelector("body"),
    palette: {
        popup: { background: "#fff" },
        button: { background: "rgb(158 91 64 / 100%)" },
    },
    revokable: true,
    theme: "edgeless",
    type: "opt-in",
    law: {
        regionalLaw: false,
    },
    location: true,
    content: {
    //   header: 'Cookies used on the site!',
      message: 'This website uses cookies for site usage data.',
    //   dismiss: 'Got it!',
    //   allow: 'Allow cookies',
    //   deny: 'Decline',
    //   link: 'Learn more',
    //   href: 'https://www.cookiesandyou.com',
    //   close: '&#x274c;',
    //   policy: 'Cookie Policy',
    //   target: '_blank',
    },
    // elements: {
    //   header: '<span class="cc-header"></span>',
    //   message: '<span id="cookieconsent:desc" class="cc-message"></span>',
    //   messagelink: '<span id="cookieconsent:desc" class="cc-message"> <a aria-label="learn more about cookies" tabindex="0" class="cc-link" href="" target="_blank"></a></span>',
    //   dismiss: '<a aria-label="dismiss cookie message" tabindex="0" class="cc-btn cc-dismiss"></a>',
    //   allow: '<a aria-label="allow cookies" tabindex="0" class="cc-btn cc-allow"></a>',
    //   deny: '<a aria-label="deny cookies" tabindex="0" class="cc-btn cc-deny"></a>',
    //   link: '<a aria-label="learn more about cookies" tabindex="0" class="cc-link" href="" target="_blank"></a>',
    //   close: '<span aria-label="dismiss cookie message" tabindex="0" class="cc-close"></span>',
    // },
    onInitialise: function (status) {
        var type = this.options.type;
        var didConsent = this.hasConsented();
        if (type == 'opt-in' && didConsent) {
            // enable cookies
            enableCookies();
        }
        if (type == 'opt-out' && !didConsent) {
            // disable cookies
        }
    },
    onStatusChange: function (status, chosenBefore) {
        var type = this.options.type;
        var didConsent = this.hasConsented();
        if (type == 'opt-in' && didConsent) {
            // enable cookies
            enableCookies();
        }
        if (type == 'opt-out' && !didConsent) {
            // disable cookies
        }
    },
    onRevokeChoice: function () {
        var type = this.options.type;
        if (type == 'opt-in') {
            // disable cookies
        }
        if (type == 'opt-out') {
            // enable cookies
            enableCookies();
        }
    },
});
